<!--
 * @Author: zhongxq
 * @Date: 2023-10-20 22:27:48
 * @LastEditors: zhongxq
 * @LastEditTime: 2023-10-23 20:20:50
 * @FilePath: \beijing-peoples-art-museum\src\views\pc\culture\periodical\components\PeriodicalItem.vue
 * @Description: 期刊item
 *
-->
<template>
  <div :class="`periodical-item ${type}`" @click="handleDetail">
    <c-image :src="item.image" class="poster" />
    <div class="content">
      <p>{{ item.title }}</p>
      <p class="date-line">
        <span>{{
          item.publishDate || dayjs(item.createTime).format("YYYY-MM-DD")
        }}</span>
        <c-image :src="require('@/assets/img/performance/right.png')" />
      </p>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { getArticleDetail, articleHit } from "@/service/culture";
export default {
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: "book",
    },
  },
  data() {
    return {
      dayjs,
    };
  },
  methods: {
    handleDetail() {
      if (this.type === "shop") {
        this.$emit("toDetail");
      } else {
        // window.open(
        //   `${location.origin}${location.pathname || "/"
        //   }static/read.html?url=${encodeURIComponent(this.item.source)}`
        // );
        // window.open(`${location.origin}#/readbook?id=${this.item.id}`);
        articleHit(this.item.id);
        getArticleDetail(this.item.id).then((res) => {
          window.open(
            `${location.origin}${
              location.pathname || "/"
            }static/read.html?url=${encodeURIComponent(
              this.item.source
            )}&pageNum=${res.data.attatchmentUrl}`
          );
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.periodical-item {
  box-sizing: border-box;
  width: 15.3646vw;
  height: 26.0417vw;
  cursor: pointer;
  .poster {
    width: 15.3646vw;
    height: 21.6667vw;
    display: block;
  }

  .content {
    box-sizing: border-box;
    padding: 1.0417vw;
    width: 15.3646vw;
    height: 4.375vw;
    background: #f8f8fa;
    font-size: 0.8333vw;
    font-weight: 800;
    color: #212122;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .date-line {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0.625vw;
      font-weight: 400;
      color: #909399;
      .img {
        width: 3.0208vw;
        height: 0.7813vw;
      }
    }
  }
  &.shop {
    height: 21.875vw;
    .poster {
      height: 17.5vw;
    }
  }
}
</style>
