<!--
 * @Author: zhongxq
 * @Date: 2023-10-22 16:46:52
 * @LastEditors: zhongxq
 * @LastEditTime: 2023-10-23 14:57:15
 * @FilePath: \beijing-peoples-art-museum\src\views\pc\culture\periodical\components\ShopList.vue
 * @Description: 戏剧书店
 *
-->
<template>
  <div class="card" id="xjsd">
    <div class="sub-title">
      <span>戏剧书店</span>
    </div>
    <div class="periodical-list">
      <PeriodicalItem
        v-for="item in list"
        :key="item.id"
        :item="item"
        type="shop"
        @toDetail="toDetail"
      />
    </div>
    <div v-if="hasNextPage" class="more-btn-box">
      <button class="more-btn" @click="handleMore">查看更多</button>
    </div>
  </div>
</template>

<script>
import { getArticlePage } from "@/service/culture";
import PeriodicalItem from "./PeriodicalItem.vue";

export default {
  components: { PeriodicalItem },
  props: {
    title: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      list: [],
      form: {
        orderDirection: 1,
        orderSign: 1,
        level1: "culture",
        level2: "shop",
        status: 1,
      },
      pager: {
        pageNo: 1,
        pageSize: 8,
      },
      hasNextPage: true,
    };
  },
  created() {
    this.handleSearch();
  },
  methods: {
    async getData() {
      try {
        const res = await getArticlePage({
          ...this.pager,
          condition: {
            ...this.form,
            title: this.title,
            isShow: 1,
          },
        });
        if (res.data) {
          const data = res.data.data || [];
          if (data.length < this.pager.pageSize) {
            this.hasNextPage = false;
          }
          if (this.pager.pageNo === 1) {
            this.list = data;
          } else {
            this.list = this.list.concat(data);
          }
        }
      } catch (e) {
        if (this.pager.pageNo === 1) {
          this.list = [];
        }
      }
    },
    handleSearch() {
      this.hasNextPage = true;
      this.pager.pageNo = 1;
      this.getData();
    },
    handleMore() {
      if (!this.hasNextPage) return;
      this.pager.pageNo++;
      this.getData();
    },
    toDetail() {
      this.$emit("show");
    },
  },
};
</script>

<style lang="less" scoped>
.more-btn-box {
  margin-top: 1.5625vw;
}
</style>
