<!--
 * @Author: zhongxq
 * @Date: 2023-10-20 20:32:59
 * @LastEditors: zhongxq
 * @LastEditTime: 2023-10-30 15:07:52
 * @FilePath: \beijing-peoples-art-museum\src\views\pc\culture\periodical\index.vue
 * @Description: 悦读人艺
 *
-->
<template>
  <div class="fixed-page culture-periodical-index">
    <div class="banner">
      <div class="left">
        <div class="title">
          <h1>欢迎进入北京人艺戏剧书店微店</h1>
          <h2>在这里，你将与戏剧艺术相遇</h2>
          <h3>
            您可以关注北京人艺戏剧书店微信公众号，了解更多戏剧图书和戏剧文创
          </h3>
        </div>
      </div>
      <div class="right">
        <c-image :src="require('@/assets/img/performance/store.png')" />
        <p>扫描二维码进店</p>
      </div>
    </div>
    <Breadcrumb class="breadcrumb-1400" />
    <div class="container">
      <div class="flex-center justify-between">
        <div class="title">
          <c-image :src="require('@/assets/img/performance/title-icon.png')" />
          <span>悦读人艺</span>
        </div>
        <el-input
          v-model="form.title"
          class="input"
          suffix-icon="el-icon-search"
          @change="handleChange"
        />
      </div>
      <div class="card" v-if="lastItem.id">
        <div class="sub-title">
          <span>最新院刊</span>
        </div>
        <div class="detail-info">
          <div class="left">
            <c-image :src="lastItem.image" />
            <p>{{ lastItem.title }}</p>
          </div>
          <div class="right">
            <p class="desc">内容简介</p>
            <div class="content" v-html="lastItem.body"></div>
            <button class="detail-btn" @click="handleDetail(lastItem)">
              查看详情
            </button>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="sub-title">
          <span>往期院刊</span>
          <div class="filter" @click="handleChangeSort">
            <span>{{ form.orderDirection ? "最新" : "最早" }}</span>
          </div>
        </div>
        <div
          v-for="dateItem in dateList"
          :key="dateItem.year"
          class="date-list"
        >
          <div class="date">{{ dateItem.year }}</div>
          <div
            v-if="dateItem.children && dateItem.children.length"
            class="periodical-list"
          >
            <PeriodicalItem
              v-for="item in dateItem.children"
              :key="item.id"
              :item="item"
            />
          </div>
        </div>
        <div v-if="hasNextPage" class="more-btn-box">
          <button class="more-btn" @click="handleMore">查看更多</button>
        </div>
      </div>
      <ShopList
        ref="shopListRef"
        :title="form.title"
        @show="dialogVisible = true"
      />
    </div>
    <PageFooter />
    <el-dialog
      :visible.sync="dialogVisible"
      width="30%"
      top="20vh"
      append-to-body
    >
      <div class="qr-dialog">
        <p>手机扫码进入</p>
        <p>北京人艺戏剧书店微信公众号，</p>
        <p>购买戏剧图书和戏剧文创。</p>
        <c-image :src="require('@/assets/img/performance/store.png')" />
        <p>扫描二维码进店</p>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import dayjs from "dayjs";
import {
  getArticlePage,
  getArticleDetail,
  articleHit,
} from "@/service/culture";
import PeriodicalItem from "./components/PeriodicalItem.vue";
import ShopList from "./components/ShopList.vue";

export default {
  components: { PeriodicalItem, ShopList },
  data() {
    return {
      lastItem: {},
      list: [],
      form: {
        title: "",
        orderDirection: 1,
        orderSign: 2,
        level1: "culture",
        level2: "book",
        status: 1,
        isShow: 1,
      },
      pager: {
        pageNo: 1,
        pageSize: 8,
      },
      hasNextPage: true,
      dialogVisible: false,
    };
  },
  created() {
    this.$store.dispatch("getDict", ["read-type"]);
    this.handleSearch();
  },
  updated() {
    if (this.$route.query.type) {
      document.getElementById("xjsd").scrollIntoView({ behavior: "smooth" });
    }
  },
  computed: {
    readType() {
      return this.$store.state.dictMap["read-type"] || [];
    },
    yuankanId() {
      const obj = this.readType.find((item) => item.value === "院刊") || {};
      return obj.value || "";
    },
    dateList() {
      let yearArr = [];
      let yearObj = {};
      this.list.forEach((item) => {
        const year = dayjs(item.publishDate || item.createTime).format("YYYY");
        if (yearArr.indexOf(year) === -1) {
          yearArr.push(year);
        }
        if (!yearObj[year]) {
          yearObj[year] = [item];
        } else {
          yearObj[year].push(item);
        }
      });
      const formatList = yearArr.map((it) => ({
        year: it,
        children: yearObj[it],
      }));
      return formatList;
    },
  },
  watch: {
    yuankanId() {
      if (this.yuankanId) {
        this.handleSearch();
      }
    },
  },
  methods: {
    async getData() {
      try {
        const res = await getArticlePage({
          ...this.pager,
          condition: { ...this.form, tag: this.yuankanId },
        });
        if (res.data) {
          const data = res.data.data || [];
          if (data.length < this.pager.pageSize) {
            this.hasNextPage = false;
          }
          if (this.pager.pageNo === 1) {
            this.list = data;
            if (data[0] && !this.form.title && this.form.orderDirection) {
              this.lastItem = data[0];
            }
          } else {
            this.list = this.list.concat(data);
          }
        }
      } catch (e) {
        console.log(e);
        if (this.pager.pageNo === 1) {
          this.list = [];
        }
      }
    },
    handleSearch() {
      this.hasNextPage = true;
      this.pager.pageNo = 1;
      this.getData();
    },
    handleMore() {
      if (!this.hasNextPage) return;
      this.pager.pageNo++;
      this.getData();
    },
    handleDetail(item) {
      articleHit(item.id);
      getArticleDetail(item.id).then((res) => {
        window.open(
          `${location.origin}${
            location.pathname || "/"
          }static/read.html?url=${encodeURIComponent(item.source)}&pageNum=${
            res.data.attatchmentUrl
          }`
        );
      });
    },
    handleChangeSort() {
      this.form.orderDirection = this.form.orderDirection ? 0 : 1;
      this.handleSearch();
    },
    handleChange() {
      this.handleSearch();
      this.$refs.shopListRef?.handleSearch();
    },
  },
};
</script>

<style lang="less">
@import url("~@/assets/style/mixin.less");

.culture-periodical-index {
  p {
    padding: 0;
    margin: 0;
  }
  .font-bold {
    font-weight: bold;
  }
  .flex-center {
    display: flex;
    align-items: center;
  }
  .justify-between {
    justify-content: space-between;
  }
  .banner {
    width: 100%;
    height: 23.4375vw;
    .background-image("/performance/storebg.png");
    background-size: 100% 100%;
    position: relative;
    .left {
      width: 66%;
      height: 100%;
      background-color: rgba(191, 128, 95, 0.7);
      backdrop-filter: blur(0.3125vw);
      clip-path: polygon(0 0, 100% 0, 88% 100%, 0 100%, 0 0);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      line-height: 4.0625vw;
      h1,
      h2,
      h3 {
        margin: 0;
      }
      h1 {
        font-size: 2.8125vw;
        font-weight: 800;
      }
      h2 {
        font-size: 2.6042vw;
        font-weight: 400;
      }
      h3 {
        font-size: 1.25vw;
        line-height: 1.25vw;
        font-weight: 400;
        margin-top: 2.6042vw;
      }
    }
    .right {
      position: absolute;
      right: 10%;
      top: 2.9167vw;
      width: 14.5833vw;
      height: 17.1875vw;
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      .img {
        width: 14.5833vw;
        height: 14.5833vw;
      }
      p {
        color: #979797;
        font-size: 1.25vw;
      }
    }
  }
  .container {
    margin: 0 auto 2.6042vw;
    box-sizing: border-box;
    width: 72.9167vw;
    padding: 2.6042vw;
    background-color: #ffffff;
    .title {
      font-size: 1.3542vw;
      font-weight: 500;
      color: #212122;
      padding-left: 1.3542vw;
      position: relative;
      .img {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 0.7292vw;
        height: 1.3542vw;
      }
    }
    .input {
      width: 14.5833vw;
      height: 1.875vw;
      .el-input__icon {
        color: #212122;
        font-weight: bold;
      }
    }
    .sub-title {
      margin: 3.125vw 0 1.5625vw;
      font-size: 1.0417vw;
      font-weight: 800;
      color: #212122;
      line-height: 1.5625vw;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .filter {
        width: 2.8125vw;
        height: 1.25vw;
        background: #f8f8fa;
        border-radius: 0.1042vw;
        font-size: 0.6771vw;
        font-weight: 400;
        color: #606266;
        line-height: 1.25vw;
        padding: 0 0.2083vw;
        box-sizing: border-box;
        text-align: right;
        position: relative;
        cursor: pointer;
        &::before {
          content: "";
          position: absolute;
          left: 0.2083vw;
          top: 0.2083vw;
          width: 0.8333vw;
          height: 0.8333vw;
          .background-image("/performance/sort.png");
          background-size: 100%;
        }
      }
    }
    .detail-info {
      display: flex;
      .left {
        flex-shrink: 0;
        width: 17.7083vw;
        height: 25vw;
        margin-right: 2.0833vw;
        position: relative;
        .img {
          width: 100%;
          height: 100%;
          display: block;
        }
        p {
          color: #ffffff;
          font-size: 1.0417vw;
          font-weight: 800;
          position: absolute;
          left: 0;
          bottom: 0;
          width: 17.7083vw;
          height: 2.6042vw;
          background: rgba(0, 0, 0, 0.3);
          text-align: center;
          line-height: 2.6042vw;
        }
      }
      .right {
        flex: 1;
        box-sizing: border-box;
        font-size: 0.8333vw;
        padding: 1.5625vw 0;
        position: relative;
        .desc {
          color: #212122;
          font-weight: 500;
          margin-bottom: 1.0417vw;
        }
        .content {
          color: #606266;
          line-height: 1.7708vw;
          white-space: pre-wrap;
          height: 16.1458vw;
          width: 100%;
          overflow-y: auto;
          &::-webkit-scrollbar {
            width: 0.3125vw;
          }
          &::-webkit-scrollbar-track {
            width: 0.3125vw;
            background-color: transparent;
            border-radius: 0.5208vw;
          }
          &::-webkit-scrollbar-thumb {
            width: 0.3125vw;
            background-color: rgba(249, 104, 13, 0.4);
            border-radius: 0.5208vw;
          }
        }
        .detail-btn {
          position: absolute;
          bottom: 1.5625vw;
          left: 0;
          background-color: var(--primary-color);
          color: #ffffff;
          width: 5.8333vw;
          height: 1.7708vw;
          font-size: 0.7292vw;
          border-radius: 0.2083vw;
          outline: none;
          border: none;
          cursor: pointer;
        }
      }
    }
    .periodical-list {
      display: grid;
      gap: 2.0833vw;
      grid-template-columns: repeat(4, 15.3646vw);
    }
    .date-list {
      .date {
        height: 1.6667vw;
        background: #f8f8fa;
        padding-left: 0.8333vw;
        font-size: 0.8333vw;
        font-weight: 800;
        color: #f9680d;
        line-height: 1.6667vw;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 0.2083vw;
          height: 1.6667vw;
          background: #f9680d;
        }
      }
      .periodical-list {
        padding: 1.0417vw 0 1.5625vw;
      }
    }
    .more-btn-box {
      text-align: center;
      .more-btn {
        width: 21.9792vw;
        height: 2.1875vw;
        background: #ffffff;
        border-radius: 0.2083vw;
        border: 0.0521vw solid #d8dade;
        font-size: 0.7292vw;
        color: #212122;
        cursor: pointer;
      }
    }
  }
}
.qr-dialog {
  text-align: center;
  .img {
    width: 12.5vw;
    height: 12.5vw;
    margin: auto;
  }
  p {
    margin: 0.5208vw 0;
  }
}
</style>
